<!-- Add "scoped" attribute to limit CSS to this component only -->

<style lang="scss" scoped>
h3 {
    margin: 40px 0 0;
}

ul {
    list-style-type: none;
    padding: 0;
}

li {
    display: inline-block;
    margin: 0 10px;
}

a {
    color: #42b983;
}

.box {
    opacity: 0.98;
    margin: 8% auto;
    width: 75%;
    height: auto;
    background-color: rgba(255, 255, 255, 1.0);
    border-radius: 5px;
    border: 1px solid #ddd;

    .upload {
        margin: 30px auto;
        width: 90%;
        height: 90%;

        .ivu-upload {
            .ivu-upload-drag {
                border: 4px dashed #0045d0;
            }
        }
    }
}

.box-mobile {
    margin: 45% auto;
    width: 80%;
    height: 450px;
    background-color: rgba(255, 255, 255, 1.0);
    border-radius: 5px;
    border: 1px solid #ddd;

    .upload-mobile {
        padding: 30px 30px;
    }
}

.background {
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    /**宽高100%是为了图片铺满屏幕 */
    z-index: -1;
    position: absolute;
}
</style>

<template>
<div>
    <Header></Header>
    <div v-if="client_width >= 1300">
        <div style="margin:0px auto;position:fixed;top:0px;height:100%;width:1980px;overflow:hidden;">
            <vue-particles color="#f4f4f4" :particleOpacity="0.7" :particlesNumber="20" shapeType="circle" :particleSize="4" linesColor="#f4f4f4" :linesWidth="1" :lineLinked="true" :lineOpacity="0.4" :linesDistance="150" :moveSpeed="3" :hoverEffect="true" hoverMode="grab" :clickEffect="true" clickMode="push" />
        </div>

        <img class="background" src="@/assets/images/back.jpg" width="100%" height="100%" alt="不出名CAJ转PDF背景图" />
        <div class="box">
            <Table border :columns="columns" :data="data"></Table>
        </div>
    </div>
    <div v-else>
        <img class="background" src="@/assets/images/back.jpg" width="100%" height="100%" alt="不出名CAJ转PDF背景图" />
        <div class="box-mobile">
            <div class="upload-mobile">
                <Upload type="drag" action="" :before-upload="beforeUpload" accept=".caj" :disabled="disabled_uploader">
                    <div style="padding: 20px 0">
                        <Icon type="ios-cloud-upload" size="52" style="color: #3399ff"></Icon>
                        <p>{{ uploader_intro }}</p>
                    </div>
                </Upload>
                <br />
                <Steps direction="vertical" :current="current">
                    <Step content="上传待转换的CAJ文件"></Step>
                    <Step :content="queue_intro"></Step>
                    <Step :content="handling_intro"></Step>
                    <Step content="转换完成在线预览下载"></Step>
                </Steps>
            </div>
        </div>
    </div>
    <Footer></Footer>
</div>
</template>

<script>
import {
    BosClient
} from "@baiducloud/sdk";
import Header from '@/components/header/header.vue';
import Footer from '@/components/header/footer.vue';
import {
    task_create,
    file_create,
    task_status,
    task_auth,
    count_api
} from "@/api/api.js";
import {
    stringify
} from 'qs';
export default {
    name: "HelloWorld",
    props: {
        msg: String,
    },
    components: {
        Header,
        Footer
    },
    data() {
        return {
            current: 0,
            disabled_uploader: false,
            uploader_intro: "点击上传 CAJ 格式的文件",
            queue_intro: "查询服务器状态",
            handling_intro: "CAJ文件转换为PDF文件",
            timer: null,
            file_uuid: '',
            client_width: null,
            count: 0,
            columns: [{
                    title: '软件名称',
                    key: 'name',
                    render: (h, params) => {
                        return h('div', [
                            h('Icon', {
                                props: {
                                    type: 'person'
                                }
                            }),
                            h('strong', params.row.name)
                        ]);
                    }
                },
                {
                    title: '操作系统',
                    key: 'age'
                },
                {
                    title: '更新时间',
                    key: 'address'
                },
                {
                    title: '软件大小',
                    key: 'size'
                },
                {
                    title: '操作',
                    key: 'action',
                    width: 150,
                    align: 'center',
                    render: (h, params) => {
                        return h('div', [
                            h('Button', {
                                props: {
                                    type: 'primary',
                                    size: 'small'
                                },
                                style: {
                                    marginRight: '5px'
                                },
                                on: {
                                    click: () => {
                                        this.show(params.index)
                                    }
                                }
                            }, '下载'),
                            h('Button', {
                                props: {
                                    type: 'error',
                                    size: 'small'
                                },
                                on: {
                                    click: () => {
                                        this.remove(params.index)
                                    }
                                }
                            }, '教程')
                        ]);
                    }
                }
            ],
            data: [{
                    name: 'John Brown',
                    age: 18,
                    address: 'New York No. 1 Lake Park'
                },
                {
                    name: 'Jim Green',
                    age: 24,
                    address: 'London No. 1 Lake Park'
                },
                {
                    name: 'Joe Black',
                    age: 30,
                    address: 'Sydney No. 1 Lake Park'
                },
                {
                    name: 'Jon Snow',
                    age: 26,
                    address: 'Ottawa No. 2 Lake Park'
                }
            ]
        };
    },
    metaInfo() {
        return {
            title: "CAJ转PDF免费可复制保留书签 | 不出名CAJ转PDF",
            meta: [{
                    name: "keywords",
                    content: "CAJ转可复制PDF,CAJ转PDF,免费CAJ转PDF,2M以上CAJ转PDF"
                },
                {
                    name: "description",
                    content: "本站提供免费的CAJ转PDF，且不限制CAJ文件的大小，转换得到的PDF文件文字可进行复制且保留原CAJ文件的书签和目录。同时还提供CAJ文件的在线浏览器功能，我们承诺CAJ转PDF功能永久免费。"
                },
            ],
        };
    },
    mounted() {
        var that = this;
        count_api({}).then((res) => {
            that.count = res;
        })
        var task_id = window.localStorage.getItem("task_id");
        this.client_width = document.body.clientWidth;
        window.onresize = function () {
            that.client_width = document.documentElement.clientWidth;
            console.log("宽度", document.documentElement.clientWidth);
            console.log("高度", document.documentElement.clientHeight);
        };
        console.log("task_id=", task_id);

        if (task_id != null && task_id != '' && task_id.length != 0 && task_id != 'null') {
            that.handleFile(task_id);
            that.timer = setInterval(function () {
                that.handleFile(task_id);
            }, 1000 * 2);

        }
    },
    methods: {
        generateUUID() {
            var d = new Date().getTime();
            if (window.performance && typeof window.performance.now === "function") {
                d += performance.now(); //use high-precision timer if available
            }
            var uuid = "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(
                /[xy]/g,
                function (c) {
                    var r = (d + Math.random() * 16) % 16 | 0;
                    d = Math.floor(d / 16);
                    return (c == "x" ? r : (r & 0x3) | 0x8).toString(16);
                }
            );
            return uuid;
        },

        beforeUpload(file) {
            var that = this;
            task_auth().then((resa) => {
                if (resa.status == "200") {
                    that.disabled_uploader = true;
                    that.uploader_intro = "正在处理文件 " + file.name + " 请稍后";
                    console.log(file);
                    let bucket = "caj2pdf";
                    var bosConfig = {
                        credentials: {
                            ak: resa.accessKeyId, // STS服务器下发的临时ak
                            sk: resa.secretAccessKey, // STS服务器下发的临时sk
                        },
                        sessionToken: resa.securityToken, // STS服务器下发的sessionToken
                        endpoint: "https://gz.bcebos.com",
                    };
                    let client = new BosClient(bosConfig);
                    this.file_uuid = that.generateUUID() + ".caj";
                    client
                        .putObjectFromBlob(bucket, this.file_uuid, file)
                        .then((response) => {
                            console.log(response);
                            //提交task
                            var myDate = new Date();
                            var taskCreateParam = {};
                            taskCreateParam.cajkey = this.file_uuid;
                            taskCreateParam.cajurl = file.name;
                            taskCreateParam.pdfkey = "";
                            taskCreateParam.pdfurl = "";
                            taskCreateParam.taskstatus = "0";
                            taskCreateParam.tasktime = myDate.toLocaleString();
                            taskCreateParam.userid = 0;

                            task_create(taskCreateParam).then((res) => {
                                window.localStorage.setItem("task_status", stringify(res));
                                that.current = 1;
                                that.handleFile(res.task_id);
                                that.timer = setInterval(function () {
                                    that.handleFile(res.task_id);
                                }, 1000 * 2);
                                console.log(res);
                                that.$Notice.info({
                                    title: "通知",
                                    desc: file.name + " 文件上传成功，即将进入排队",
                                });
                                var fileCreateParam = {
                                    filename: file.name,
                                    filesize: file.size,
                                    filetype: "string",
                                    fileuuid: this.file_uuid,
                                    uploadtime: "string",
                                    userid: "string",
                                };
                                file_create(fileCreateParam).then((res) => {
                                    console.log(res);
                                });
                            });
                        })
                        .catch((error) => console.error(error)); // 失败
                } else {
                    that.$Notice.info({
                        title: "通知",
                        desc: "上传失败",
                    });
                }
            })
            return false;
        },
        handleFile(task_id) {
            var that = this;
            //将task_id保存到localStorage中
            window.localStorage.setItem("task_id", task_id);
            task_status({
                taskId: task_id,
            }).then((ress) => {
                window.localStorage.setItem("task_status", stringify(ress));
                console.log(ress);
                if (ress.status == "100") {
                    that.$Notice.info({
                        title: "通知",
                        desc: "等待空闲转换机器中",
                    });
                    that.current = 1;
                    that.queue_intro =
                        "排队等待中 " + ress.wait_num + "/" + (ress.wait_num + 1);
                }
                if (ress.status == "101") {
                    that.$Notice.info({
                        title: "通知",
                        desc: "正在进行转换，预计用时10秒",
                    });
                    that.current = 2;
                    that.handling_intro = "正在转换...预计用时10秒";
                }
                if (ress.status == "102") {
                    //带参数跳转
                    var show_status = window.localStorage.getItem("show_status");
                    console.log("show_status=", show_status);
                    console.log(ress);
                    if (ress.task_status == '500') {
                        if (show_status != ress.task_id) {
                            this.$Message.error({
                                content: "暂不支持解析HA格式的CAJ文件，该功能正在急速开发...",
                                duration: 5
                            });
                            window.localStorage.setItem("show_status", ress.task_id);
                            that.current = 0;
                            that.disabled_uploader = false;
                            that.uploader_intro = "点击上传 CAJ 格式的文件";
                            that.queue_intro = "排队等待";
                            that.handling_intro = "正在处理";
                            clearInterval(that.timer);
                            return;
                        }
                    }
                    if (show_status != ress.task_id) {
                        that.$Notice.info({
                            title: "通知",
                            desc: ress.msg + "，即将自动下载",
                        });
                        that.$Notice.info({
                            title: "通知",
                            desc: "转换完毕，即将自动下载",
                        });
                        that.current = 3;
                        that.handling_intro = "处理完成，即将自动下载";
                        clearInterval(that.timer);
                        //back
                        that.current = 0;
                        that.disabled_uploader = false;
                        that.uploader_intro = "点击上传 CAJ 格式的文件";
                        that.queue_intro = "排队等待";
                        that.handling_intro = "正在处理";
                        localStorage.setItem("menu", 2);
                        localStorage.setItem("task_detail", JSON.stringify(ress));
                        window.localStorage.setItem("show_status", ress.task_id);
                        this.$router.push({
                            path: "/doc",
                            query: {
                                pt: this.file_uuid,
                                ps: 1,
                                pe: 1
                            },
                        });
                    } else {
                        that.current = 0;
                        that.disabled_uploader = false;
                        that.uploader_intro = "点击上传 CAJ 格式的文件";
                        that.queue_intro = "排队等待";
                        that.handling_intro = "正在处理";
                    }
                    clearInterval(that.timer);
                }
                if (ress.status == "404") {
                    clearInterval(that.timer);
                }
            });
        }
    },
};
</script>
